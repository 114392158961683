<template>
  <div id="PTpage" :class="getMobileTableClass">
    <header id="header">
      <div id="PTheadWrap">
        <ul class="coninpointPT">
          <li>
            <a @click="goPageByName('main')" class="PTLogoPC PTLogo"> <img src="@/assets/img/icon_home.svg"></a>
            <a @click="goPageByName('mobileMain')" class="PTLogoM PTLogo"> <img src="@/assets/img/icon_home.svg"></a>
          </li>
          <li>
            <h1 class="PTpageName">파트너 페이지</h1>
          </li>
        </ul>
        <ul class="coninpointPT" v-if="userData">
          <li><h2 class="PTmemName"><span>{{userData.memNick}}</span></h2></li>
          <li><img src="@/assets/img/hcoin.svg" /><span>{{thousand(userData.cashAmt ? userData.cashAmt.toString(): '0')}}</span></li>
          <li style="cursor: pointer" @click="goPageByName('point')"><img src="@/assets/img/hpoint.svg" /><span>{{thousand(userData.pointAmt ? userData.pointAmt.toString() : '0')}}</span></li>
          <li class="mymsgsPT" @click="goPageByName('message')">
            <em><img src="@/assets/img/hmsg.svg"></em>
            <span class="PTmsg">{{thousand(userData.unReadMessageCount ? userData.unReadMessageCount.toString() : '0')}}</span>
          </li>
        </ul>
      </div>
    </header>

    <div id="PTbodyWrap">
      <div class="PTtabWrap">
        <ul style="display:flex; cursor:pointer;">
          <li class="" data-tab="tab-5" @click="onChangeTab('partnerCashList')" :class="{'current': currentTab == 'partnerCashList'}">
           <div>
             <span class=" PTtab1img PTtabimg"></span>
             <h3>입·출금내역<p>Calculation</p></h3>
           </div>
          </li>
          <li class="" data-tab="tab-6" @click="onChangeTab('partnerBettingList')" :class="{'current': currentTab == 'partnerBettingList'}">
           <div>
             <span class=" PTtab1img PTtabimg"></span>
             <h3>베팅내역<p>Calculation</p></h3>
           </div>
          </li>
          <li class="" data-tab="tab-1" @click="onChangeTab('partnerCalculationList')" :class="{'current': currentTab == 'partnerCalculationList'}">
           <div>
             <span class=" PTtab1img PTtabimg"></span>
             <h3>파트너정산내역<p>Calculation</p></h3>
           </div>
          </li>
          <li class="" data-tab="tab-4" @click="onChangeTab('partnerCalculationUserList')" :class="{'current': currentTab == 'partnerCalculationUserList'}">
           <div>
             <span class=" PTtab1img PTtabimg"></span>
             <h3>유저정산내역<p>Calculation</p></h3>
           </div>
          </li>
          <li class="" data-tab="tab-2" @click="onChangeTab('partnerList')" :class="{'current': currentTab == 'partnerList' , 'disabled': userData.partnerLevel === 'PTN_5'}">
           <div>
             <span class=" PTtab2img PTtabimg"></span>
             <h3>하부파트너목록<p>Partner List</p></h3>
           </div>
          </li>
          <li class="" data-tab="tab-3" @click="onChangeTab('partnerMemberList')" :class="{'current': currentTab == 'partnerMemberList'}">
           <div>
             <span class=" PTtab2img PTtabimg"></span>
             <h3>소속회원목록<p>User List</p></h3>
           </div>
          </li>
          <li class="" data-tab="tab-7" @click="onChangeTab('partnerMoneyMoveList')" :class="{'current': currentTab == 'partnerMoneyMoveList'}">
           <div>
             <span class=" PTtab1img PTtabimg"></span>
             <h3>회원머니지급<p>User Money</p></h3>
           </div>
          </li>
        </ul>
      </div>
      <div class="PTboardwrap">
        <div class="">
          <!--<div class="stribuname"><span>{{userData.memNick}}</span> {{$t('front.stributor.strInfo')}}</div>-->
          <div class="currentPT">
            <ul class="strheadPT">
              <li><h3 class="pagename2">현황</h3></li>
              <li class="ptUser"><span>{{$t('front.stributor.bottomPT')}}</span><span class="strnum" v-if="summaryNew">{{thousand(summaryNew.partnerCnt)}}</span></li>
              <li class="ptUser"><span>{{$t('front.stributor.bottomU')}}</span><span class="strnum" v-if="summaryNew">{{thousand(summaryNew.botMemCnt)}}</span></li>
              <li class="ptUser"><span>{{$t('front.stributor.inUsers')}}</span><span class="strnum" v-if="summaryNew">{{thousand(summaryNew.totalMemCnt)}}</span></li>
              <li class="ptUser"><span>{{$t('front.stributor.todayJoin')}}</span><span class="strnum" v-if="summaryNew">{{thousand(summaryNew.todayJoincnt)}}</span></li>
            </ul>
            <a class="saveBtnPT mb0" @click="onChangePartnerAddPopup" v-if="userData.partnerLevel !== 'NORMAL'">회원생성</a>
          </div>
          <router-view :key="$route.fullPath" @rateOpen="move = true"></router-view>
          <!------------------------------------------------------------------------------->
          <div v-if="make" class="moveWrap">
            <div class="tabmenu_make moveWraphead">
              <ul>
                <li @click="currentTab2 = 'makeTab1'" :class="{'current': currentTab2 == 'makeTab1'}" v-if="newPartner.partnerLevel !== 'PTN_5' && userData.partnerLevel !== 'PTN_5'">파트너</li>
                <li @click="currentTab2 = 'makeTab2'" :class="{'current': currentTab2 == 'makeTab2'}">회원</li>
              </ul>
              <a class="close" @click="onChangePartnerAddPopup"><img src="@/assets/img/icon_cancel.png" /></a>
            </div>
            <div class="tabcont_make moveWrapbody" v-if="currentTab2 == 'makeTab1' && newPartner.partnerLevel !== 'PTN_5' && userData.partnerLevel !== 'PTN_5' ">
              <h4>파트너 생성</h4>
              <table class="part">
                <colgroup>
                  <col width="20%"/>
                  <col width="30%"/>
                  <col width="20%"/>
                  <col width="30%"/>
                </colgroup>
                <tr>
                  <th>아이디</th>
                  <td><input class="in" name="newMemId" type="text" v-model="newPartner.memId" autocomplete="off" role="presentation"/></td>
                  <th>비밀번호</th>
                  <td><input class="in" name="newMemPass" type="password" v-model="newPartner.memPass" autocomplete="off"/></td>
                </tr>
                <tr>
                  <th>닉네임</th>
                  <td><input class="in" type="text" v-model="newPartner.memNick"/></td>
                  <th>출금 비밀번호</th>
                  <td><input class="in password" pattern="[0-9]*" type="number" v-model="newPartner.cashOutPass"/></td>
                </tr>
                <tr>
                  <th>생성파트너등급</th>
                  <td>{{newPartnerLevel.codeName}}</td>
                  <th>계좌정보</th>
                  <td class="accountInfo">
                    <bank-list :className="'in'" @onChange="onChangeBank"></bank-list>
                    <input class="in" type="text" v-model="newPartner.bankAcc"/>
                  </td>
                </tr>
                <tr>
                  <th>이름</th>
                  <td><input class="in" type="text" v-model="newPartner.memName"/></td>
                  <th>전화번호</th>
                  <td><input class="in" type="text" v-model="newPartner.memPhone"/></td>
                </tr>
              </table>
              <h4>게임별 요율설정</h4>
              <table>
                <tr>
                  <th :colspan="Object.keys(newPartner.rateGroup).length">{{$t('front.stributor.roll')}}(%)</th>
                  <th :colspan="Object.keys(newPartner.rateGroup).length">{{$t('front.stributor.lose')}}(%)</th>
                </tr>
                <tr>
                  <th v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                    {{$t(`front.gnb.${key}`)}}
                  </th>
                  <th v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                    {{$t(`front.gnb.${key}`)}}
                  </th>
                </tr>
                <tr>
                  <td v-for="(item) in newPartner.rateGroup" v-bind:key="item">
                    <a class="rbnt">최대값 : {{item.maxPointRate}}</a>
                    <a class="rbnt">최소값 : {{item.minPointRate}}</a>
                  </td>
                  <td v-for="(item) in newPartner.rateGroup" v-bind:key="item">
                    <a class="rbnt">최대값 : {{item.maxLoseRate}}</a>
                    <a class="rbnt">최소값 : {{item.minLoseRate}}</a>
                  </td>
                </tr>
                <tr>
                  <td v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                    <input type="text" v-model="item[`${key}PR`]">
                  </td>
                  <td v-for="(item, key) in newPartner.rateGroup" v-bind:key="item">
                    <input type="text" v-model="item[`${key}LR`]">
                  </td>
                </tr>
              </table>
              <a class="btn" @click="onAddPartnerSubmit">{{$t('front.stributor.save')}}</a>
            </div>

            <div class="tabcont_make moveWrapbody" v-if="currentTab2 == 'makeTab2'">
              <h4>회원 생성</h4>
              <table class="part">
                <colgroup>
                  <col width="20%"/>
                  <col width="30%"/>
                  <col width="20%"/>
                  <col width="30%"/>
                </colgroup>
                <tr>
                  <th>아이디</th>
                  <td><input class="in" type="text" autocomplete="off" v-model="newMember.memId"/></td>
                  <th>출금 비밀번호</th>
                  <td><input class="in password" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" type="text" maxlength="4" pattern="[0-9]*" v-model="newMember.cashOutPass" inputmode="numeric"  /></td>
                </tr>
                <tr>
                  <th>비밀번호</th>
                  <td><input class="in" type="password" autocomplete="off" v-model="newMember.memPass"/></td>
                  <th>전화번호</th>
                  <td><input class="in" type="text" v-model="newMember.memPhone"/></td>
                </tr>
                <tr>
                  <th>닉네임</th>
                  <td><input class="in" type="text" v-model="newMember.memNick"/></td>
                  <th>추천인 아이디</th>
                  <td>{{newPartner.recommenderId}}({{partnerLevelName}})
                    <input class="in" type="hidden" v-model="newMember.recommenderId"/>
                  </td>
                </tr>
                <tr>
                  <th>이름</th>
                  <td><input class="in" type="text" v-model="newMember.memName"/></td>
                  <th>계좌정보</th>
                  <td class="accountInfo">
                    <bank-list :className="'in'" @onChange="onChangeBankMember"></bank-list>
                    <input class="in w45w" type="text" v-model="newMember.bankacc" oninput="this.value = this.value.replace(/[^0-9.]/g, '')" />
                  </td>
                </tr>
              </table>
              <a class="btn" @click="newMemberSignup()">등록</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getRetailSummary, partnerJoin, partnerLevels, partnerRates
} from '@/api/retail'
import {
  isValidOnlyNumber,
  isValidOnlyPhoneNumber,
  isValidPassword,
  isValidPasswordPartner,
  isValidUserId,
  thousand
} from '@/libs/utils'
import { mapState } from 'vuex'
import BankList from '@/components/ui/BankList.vue'
import { PARTNER_LEVEL_NAME, PARTNER_MOBILE_TABLE_SITE } from '@/libs/constants'
import { signUp } from '@/api/member'
export default {
  name: 'Partner',
  components: { BankList },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ]),
    partnerLevelName () {
      return PARTNER_LEVEL_NAME[this.userData.partnerLevel]
    },
    getMobileTableClass () {
      const siteId = process.env.VUE_APP_SITE_ID || ''
      let mobileTableClass = ''
      if (PARTNER_MOBILE_TABLE_SITE.includes(siteId)) {
        mobileTableClass = 'mobileTable'
      }
      return mobileTableClass
    }
  },
  data () {
    return {
      currentTab: this.$route.name,
      currentTab2: 'makeTab2',
      summary: {},
      rate: {},
      move: false,
      make: false,
      myRate: {},
      myChildrenRate: [],
      newPartner: {
        memId: '',
        memPass: '',
        memNick: '',
        memPhone: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        rateGroup: {}
      },
      defaultNewPartner: {
        memId: '',
        memPass: '',
        memNick: '',
        memPhone: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        rateGroup: {}
      },
      newMember: {
        memId: '',
        memPass: '',
        memNick: '',
        memPhone: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        memLevel: '1',
        partSendYn: 'N',
        firstBetConfYn: 'N',
        memStatus: 0
      },
      defaultMember: {
        memId: '',
        memPass: '',
        memNick: '',
        memPhone: '',
        bank: '',
        bankAcc: '',
        cashOutPass: '',
        memLevel: '1',
        partSendYn: 'N',
        firstBetConfYn: 'N',
        memStatus: 0
      },
      rateMaxMinList: [],
      newPartnerLevel: {},
      partnerLevelList: [],
      partnerList: [],
      summaryNew: null
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    this.getPartnerLevels()
    this.getSummary()
    await this.getPartnerRateForNewPartner()
    this.newPartnerLevel = this.partnerLevelList[0]
    this.emitter.emit('Loading', false)
  },
  watch: {
    'newPartner.partnerLevel': function (newLevel) {
      if (this.newPartner.partnerLevel === 'PTN_5' || this.userData.partnerLevel !== 'PTN_5') {
        this.currentTab2 = 'makeTab2'
      } else {
        this.currentTab2 = 'makeTab1'
      }
    }
  },
  methods: {
    onChangeBankMember (value) {
      this.newMember.bank = value
    },
    isMemberSignupValidate () {
      const data = this.newMember
      for (const key of Object.keys(data)) {
        const value = data[key]
        if (key === 'memId') {
          if (value === '') {
            this.onAlert('warningart', 'front.member.emptyMemId')
            return false
          }

          if (!isValidUserId(value)) {
            this.onAlert('warningart', 'api.U101')
            return false
          }
        }

        if (key === 'memPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemPass')
            return false
          }

          if (!isValidPassword(value) || value.length > 20) {
            this.onAlert('warningart', 'api.U102')
            return false
          }
        }

        if (key === 'cashOutPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyCashOutPass')
            return false
          }

          if (!isValidOnlyNumber(value) || value.length !== 4) {
            this.onAlert('warningart', 'api.U103')
            return false
          }
        }

        if (key === 'memNick') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemNick')
            return false
          }

          if (value.length > 20 || value.length < 3) {
            this.onAlert('warningart', 'api.U104')
            return false
          }
        }

        if (key === 'memPhone') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemTel')
            return false
          }

          if (!isValidOnlyPhoneNumber(value)) {
            this.onAlert('warningart', 'front.member.noValidMemTel')
            return false
          }
        }

        if (key === 'recommenderId') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyRecommenderId')
            return false
          }
        }

        if (key === 'bank') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyBankName')
            return false
          }
        }

        if (key === 'memName') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyBankAccountName')
            return false
          }
        }

        if (key === 'bankacc') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyBankNumber')
            return false
          }

          if (!isValidOnlyNumber(value)) {
            this.onAlert('warningart', 'front.member.noValidBankNumber')
            return false
          }
        }
      }

      return true
    },
    newMemberSignup () {
      this.newMember.recommenderId = this.newPartner.recommenderId
      if (this.isMemberSignupValidate()) {
        this.newMember.memPhone = this.newMember.memPhone.toString()
        signUp(this.newMember).then(async response => {
          const result = response.data
          if (result.resultCode === '0') {
            await this.onCheck('front.member.completeSignup')
            this.makesub = false
            this.newMember = {
              ...this.defaultMember
            }
          } else {
            this.onAlert('warningart', 'api.' + result.resultCode)
          }
        }).catch(err => {
          console.error('singup err : ', err)
        })
      }
    },
    onChangeTab (name) {
      this.currentTab = name
      this.goPageByName(name)
    },
    thousand,
    getPartnerLevels () {
      partnerLevels({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.partnerLevelList = result.data.list
          this.newPartnerLevel = result.data.list[0]

          const partnerObj = {}
          for (let i = 0; i < this.partnerLevelList.length; i++) {
            const item = this.partnerLevelList[i]
            const code = item.code
            const codeName = item.codeName

            if (!partnerObj[code]) {
              partnerObj[code] = codeName
            }
          }

          this.partnerLevelObject = partnerObj
        }
      })
    },
    onChangePartnerAddPopup () {
      this.make = !this.make
      if (this.make) {
        this.newPartner.recommenderId = this.userData.memId
        const list = this.rateMaxMinList
        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          const vendorCode = item.vendorCode
          let groupCode = this.commonCodeByCode[vendorCode].groupCode
          const groupCodeName = this.commonCodeByCode[vendorCode].groupCodeName
          if (groupCode === 'minigame') {
            groupCode = 'mini'
          }
          if (groupCode === 'casino' && groupCodeName.indexOf('HC-') > -1) {
            groupCode = 'hcasino'
          }
          if (groupCode) {
            this.newPartner.rateGroup[groupCode] = {
              ...item
            }
            this.newPartner.rateGroup[groupCode][`${groupCode}PR`] = '0.0'
            this.newPartner.rateGroup[groupCode][`${groupCode}LR`] = '0.0'
          }
        }
      }
    },
    getPartnerRateForNewPartner () {
      return partnerRates({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          const list = result.data.rateMaxMinList
          this.rateMaxMinList = list
        }
      })
    },
    onAddPartnerSubmit () {
      const reqData = { ...this.newPartner, rateGroup: {} }

      for (const groupCode in this.newPartner.rateGroup) {
        const item = this.newPartner.rateGroup[groupCode]
        const pr = item[`${groupCode}PR`]
        if (pr) {
          if (Number(item.maxPointRate) < Number(pr) || Number(item.minPointRate) > Number(pr)) {
            this.onAlert('warningart', `front.stributor.${groupCode}RollingError`)
            return false
          }
        } else {
          this.onAlert('warningart', `front.stributor.${groupCode}RollingError`)
          return false
        }
        const lr = item[`${groupCode}LR`]
        if (lr) {
          if (Number(item.maxLoseRate) < Number(lr) || Number(item.minLoseRate) > Number(lr)) {
            this.onAlert('warningart', `front.stributor.${groupCode}LoseError`)
            return false
          }
        } else {
          this.onAlert('warningart', `front.stributor.${groupCode}LoseError`)
          return false
        }

        reqData.rateGroup[`${groupCode}PR`] = pr
        reqData.rateGroup[`${groupCode}LR`] = lr
      }

      if (!reqData.memId) {
        this.onAlert('warningart', 'front.member.emptyMemId')
        return false
      }

      if (!isValidUserId(reqData.memId)) {
        this.onAlert('warningart', 'api.U101')
        return false
      }

      if (!reqData.memPass) {
        this.onAlert('warningart', 'front.member.emptyMemPass')
        return false
      }

      if (!isValidPasswordPartner(reqData.memPass) || reqData.memPass.length > 20) {
        this.onAlert('warningart', 'api.U102')
        return false
      }

      if (!reqData.memNick) {
        this.onAlert('warningart', 'front.member.emptyMemNick')
        return false
      }
      if (!reqData.cashOutPass) {
        this.onAlert('warningart', 'front.member.emptyCashOutPass')
        return false
      }
      if (!reqData.bank) {
        this.onAlert('warningart', 'front.member.emptyBankName')
        return false
      }
      if (!reqData.bankAcc) {
        this.onAlert('warningart', 'front.member.emptyBankNumber')
        return false
      }
      if (!reqData.memName) {
        this.onAlert('warningart', 'front.member.emptyMemName')
        return false
      }
      if (!reqData.memPhone) {
        this.onAlert('warningart', 'front.member.emptyMemTel')
        return false
      }

      console.log(reqData)

      return partnerJoin(reqData).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.onCheck('하위파트너 생성신청이 완료되었습니다. 관리자에서 승인되면 가입처리가 완료됩니다.')
          this.onChangePartnerAddPopup()
          this.newPartner = { ...this.defaultNewPartner }
        } else {
          console.log(res)
          this.onAlert('warningart', result.resultMessage)
        }
      })
    },
    onChangeBank (value) {
      this.newPartner.bank = value
    },
    getSummary () {
      getRetailSummary({}).then(response => {
        const data = response.data
        if (data.resultCode === '0') {
          this.summary = data.data.summary
          this.summaryNew = data.data.summaryNew
        }
      })
    }
  }
}
</script>
<style>
#header {background: #eef0f5; margin-bottom: 20px;}
footer { display: none !important; }
.tab-content.current{display: inherit;}

.blc {color: #F75D4C !important;font-weight: bold;}
.rdc {color: #e1534e;font-weight: bold;}
.strTable.more {/*border: 2px solid #e5972d;*/width: 100%;}
.moreT img {margin-right: 6px;}
.strTable {width: 1320px;text-align: center;vertical-align: middle;font-size: 14px; overflow: hidden;border-collapse: collapse;border-spacing: 0;}
.strTable th {height: 40px;background: #EEF0F5;color: #000000;vertical-align: middle;}
.strTable td {height: 35px;background: #FBFCFD;vertical-align: middle;border-bottom: solid 1px #E9EAEF;white-space: nowrap;color: #000000;overflow: hidden;text-overflow: ellipsis;position: relative;}
.strTable td:last-child {border-right: 0;}
.money {text-align: left;}
.money li {padding: 0 10px;height: 30px;display: flex;align-items: center;justify-content: space-between;}
.money li:nth-child(4) {background: #FBEDED; color: #F75D4C;}
.moreBtn {box-sizing: border-box;display: flex;align-items: center;justify-content: center;color: #2F4DF2;position: absolute;top: 50%;left:5px;transform: translate(0, -50%);width: 18px;height: 18px;cursor: pointer;transition: all 0.7s ease;font-size: 22px;border:1px solid #2F4DF2;border-radius: 3px;}
/*.moreBtn .x {padding-bottom: 7px;}*/
/*.moreBtn .plus {padding-bottom: 5px;}*/
.moreBtn img {height: 22px;transform: rotate(90deg);transition: all 0.7s ease;}
.moreBtn .plus {transform: rotate(-135deg);}

.strTable td:first-child,
.strTable td:first-child .name {position: relative;}
/*.line .strTable td:first-child::before {
   content: '';
   width: 1px;
   height: 100%;
   background: #E9EAEF;
   position: absolute;
   left: 20px;
   top: 0;
}
.line .strTable .strTable td:first-child::before {left: 30px;}
.line .strTable .strTable .strTable td:first-child::before {left: 40px;}
.line .strTable .strTable .strTable .strTable td:first-child::before {left: 50px;}
.line .strTable .strTable .strTable .strTable .strTable td:first-child::before {left: 60px;}
.line .strTable .strTable .strTable .strTable .strTable .strTable td:first-child::before {left: 70px;}
.line .strTable td:first-child .name::before {
  content: '';
  width: 10px;
  height: 1px;
  background: #E9EAEF;
  position: absolute;
  left: 20px;
  top: 50%;
}*/
.line .strTable .strTable td:first-child .name::before {left: 30px;}
.line .strTable .strTable .strTable td:first-child .name::before {left: 40px;}
.line .strTable .strTable .strTable .strTable td:first-child .name::before {left: 50px;}
.line .strTable .strTable .strTable .strTable .strTable td:first-child .name::before {left: 60px;}
.line .strTable .strTable .strTable .strTable .strTable .strTable td:first-child .name::before {left: 70px;}

.line .strTable li:first-child .name {padding-left: 35px;}
.line .strTable .strTable li:first-child .name {padding-left: 45px;}
.line .strTable .strTable .strTable li:first-child .name {padding-left: 55px;}
.line .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 65px;}
.line .strTable .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 75px;}
.line .strTable .strTable .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 85px;}

.line .strTable .strTable .moreBtn {margin-left: 10px;}
.line .strTable .strTable .strTable .moreBtn {margin-left: 20px;}
.line .strTable .strTable .strTable .strTable .moreBtn {margin-left: 30px;}
.line .strTable .strTable .strTable .strTable .strTable .moreBtn {margin-left: 40px;}
.line .strTable .strTable .strTable .strTable .strTable .strTable .moreBtn {margin-left: 50px;}

#PTtab-4 .line .strTable li:first-child .name {padding-left: 20px;}

.name {padding-left: 20px;text-align: left;display: block;}
.name span {display: inline-block;font-size: 12px;color: #000000;margin-bottom: 5px;}
.name em {display: block;color: #000000;margin-top: 5px;}
.lRoll span:first-child {margin-bottom: 6px;}
.lRoll span {display: block;text-align: left;padding-left: 5px;}
.lRoll input {width: 45px;margin: 0 3px;}
.userBetBtn {border: 1px solid #2F4DF2;background: #6980FC;border-radius: 5px;font-size: 12px;padding: 5px 20px;display: inline-block;cursor: pointer;position: absolute;top: 5px;color:#fff;}
.userBetWrap {position: fixed;top: 50%;left: 50%;transform: translate(-50%,-50%);background: #fff;padding: 0 0 70px;border: 2px solid #777;z-index: 1;width: 100%;max-width: 1200px; min-height: 500px; max-height: 820px;}
.userBetWrap .close {position: absolute;right: 20px;top: 20px;cursor: pointer;display: inline-block;width: 30px; height: 30px; background: url(../../../assets/img/icon_cancelB.svg) no-repeat; background-size: contain;}
.userBetWrap .close img {height: 25px;}
.userBetWrap h4 {font-size: 20px;font-weight: bold;margin: 40px 25px 25px;position: relative;color: #000;text-align: left;}
.userBetWrap .tab-content {max-width: 1600px;width: calc(100% - 25px);margin: 0 0 30px 25px;}
.userBetWrap > div {white-space:nowrap;overflow-x: auto;-webkit-overflow-scrolling: touch;-ms-overflow-style: -ms-autohiding-scrollbar;text-align:center;}
.userBetWrap > div::-webkit-scrollbar {display: none;}
.userBetWrap .board {width: 1150px;}
.gameName {background: #fff;border-radius: 5px;border: 1px solid #60452a;color: #000;margin: 0 25px 28px;height: 30px;padding: 0 10px;display: flex;justify-content: left;}

.mybetwrap .tabs{display: flex;align-items: center;justify-content: center;gap: 7px;margin: 0 25px 28px;}
.mybetwrap .tabs li{text-align: center;width: 100%;color: #60452a;cursor: pointer;font-size: 14px;border-radius: 10px;border: solid 1px #c5ad75;background-color: #fff;padding: 9px 0;}
.mybetwrap .tabs li:hover {background: linear-gradient( to bottom, #b39e68, #decf8f, #9a7943 );}
.mybetwrap .tabs li.current:hover {background: linear-gradient( to bottom, #b39e68, #decf8f, #9a7943 );}

.mybetwrap .board>.boardw.first:first-child {background: linear-gradient( to bottom, #c5ad75, #d3aa4b);color: #fff;border: 0;border-radius: 10px 10px 0 0;}
.mybetwrap .boardw:last-child {border-bottom: 1px dotted #60452a;}
.mybetwrap .boardw {width:100%;border-bottom: 1px dotted #60452a;background: #fff;display: flex;text-align: center;justify-content: space-around;align-items: center;height:45px;line-height: 1.5em;font-size: 14px;cursor: pointer;color: #000;box-sizing: border-box;}
.mybetwrap .boardw li{width:100%;display: flex;align-items: center;justify-content: center;line-height: 1.2em;overflow: hidden;}
.mybetwrap .boardw li a {color: #000;}

/*datesearch*/
.datesearchPT .datesearch {background:none !important;}
.datesearchPT .datesearch input{background:none !important;border: 1px solid #c9c9c9 !important;color:#000 !important;}
.datesearchPT .datesearch span {color:#000 !important;}
.datesearchPT .datesearch a.bga {background:#555 !important;}
.datesearchPT .datesearch a.bgf {background:#2F4DF2 !important; color:#fff !important;}
.searchPT {display: flex; gap:10px;}
/* 등급 매장일 때 하부파트너목록 버튼 비활성화 */
.disabled { display: none; }

@media (max-width: 1000px) {
   .line {white-space: nowrap;overflow-x: auto;-webkit-overflow-scrolling: touch;-ms-overflow-style: -ms-autohiding-scrollbar;text-align: center;}
}
@media screen and (max-width: 799px) {
  /* .line .strTable li:first-child .name {padding-left: 5px;}
  .line .strTable .strTable li:first-child .name {padding-left: 5px;}
  .line .strTable .strTable .strTable li:first-child .name {padding-left: 5px;}
  .line .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 5px;}
  .line .strTable .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 5px;}
  .line .strTable .strTable .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 5px;}*/
}
</style>

<style scoped>
.pb30 {padding-bottom:30px;}
.w100w {margin: 0 20px auto;min-width: 1314px;}
.nonebtn {background: none !important;border: 0 !important;width: auto !important;}
.rollname {font-size: 16px;color: #000;margin-bottom: 18px;}
.close {position: absolute;right: 15px;top: 15px;}
.board {box-shadow: 0 0px 6px 0 rgb(0 0 0 / 20%);border-radius: 0 0 10px 10px;}
.boardstrin .rollname {margin-top: 30px;}
.board.even .boardstr {background: #f8f9fb;}
.datesearchPT {display: flex;justify-content: space-between;align-items: center;}
.datesearchPT .btn {background: #fc7220;font-size: 14px;width: 93px;height: 28px;color: #fff;line-height: 28px;text-align: center;border-radius: 5px;margin-bottom: 18px;}
.searchPT {display: flex; gap:10px;}
.PTsch .searchPT h5{line-height: 30px;padding-left:120px}
.saveBtnPT {background: #2F4DF2;font-size: 14px;padding: 0 10px;height: 28px;color: #fff;line-height: 28px;text-align: center;border-radius: 5px;display: inline-block;}
.moveWrap {position: fixed;top: 50%;left: 50%;transform: translate(-50%,-50%);background: #fff;z-index: 5;font-family: 'SUIT';box-shadow: 0 0 10px 1px #ddd;}
.moveWrap .close {position: absolute;right: 50px;top: 18px;}
.moveWrap .close img {height: 28px;}
.moveWraphead { padding: 20px 50px; background:#575C68; color: #fff; font-size: 18px; }
.moveWrapbody { padding: 0 50px 20px; }
.moveWrap h4 {font-size: 16px;font-weight: bold;margin: 40px 0 18px;position: relative;color: #575C68;}
.moveWrap table {width: 800px;text-align: center;}
.moveWrap th {height: 40px;background: #828793; border: solid 1px #DDDEE2;color: #fff;vertical-align: middle;}
.moveWrap table tr:nth-child(2) th { background:#EEF0F5; color: #575C68; }
.moveWrap .scroll table tr:nth-child(2) th { background:#828793; color: #fff;}
.moveWrap td {height: 40px;background: #FBFCFD;vertical-align: middle;border: solid 1px #EEF0F5;white-space: nowrap;color: #575C68;overflow: hidden;text-overflow: ellipsis;position: relative;}
.moveWrap input {width: 50px;border: 1px solid #0000F0;background: #fff;border-radius: 3px;color: #575C68;text-align: center;}
.moveWrap input.in {width: 90%;height: 28px;}
.moveWrap input.in.password {-webkit-text-security: disc;-moz-webkit-text-security: disc;-moz-text-security: disc;}
.moveWrap .accountInfo input.in { width: 54%; }
.moveWrap select.in {width: 80px;height: 28px;border: 1px solid #60452a;background: #fff;color: #575C68;margin-right: 5px;}
.moveWrap p {margin-top: 25px;color: #2A40B9;font-size: 12px;text-align: right;}
.moveWrap a.btn {margin-top: 25px;background: #2F4DF2;font-size: 16px;padding: 0 55px;height: 35px;color: #fff;line-height: 35px;text-align: center;border-radius: 5px;display: inline-block;margin-bottom: 18px;float: right;}
.rbnt {display: block;margin-bottom: 10px;}
.rbnt:first-child {margin-top: 10px;}
.error {border-color: #ff3f45 !important;}
.scroll {max-height: 400px;overflow-y: auto;}
.total td {background: #141414;}
.moreBtn.b {position: relative;border: solid 1px #fff;width: 50px;margin: 0 auto;}
@media screen and (max-width: 799px) {
  .moreBtn {box-sizing: border-box;display: flex;align-items: center;justify-content: center;color: #2F4DF2;position: absolute;top: 0;left:0;width: 18px;height: 18px;cursor: pointer;transition: all 0.7s ease;font-size: 22px;border:1px solid #2F4DF2;border-radius: 3px;}
  .line .strTable li:first-child .name {padding-left: 0;}
  .line .strTable .strTable li:first-child .name {padding-left: 0;}
  .line .strTable .strTable .strTable li:first-child .name {padding-left: 0;}
  .line .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 0;}
  .line .strTable .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 0;}
  .line .strTable .strTable .strTable .strTable .strTable .strTable li:first-child .name {padding-left: 0;}
}
.moveWrap2 {position: fixed;top: 50%;left: 50%;transform: translate(-50%,-50%);background: #fff;z-index: 5;font-family: 'SUIT';box-shadow: 0 0 10px 1px #ddd;}
.moveWrap2 .close {position: absolute;right: 50px;top: 18px; cursor: pointer;}
.moveWrap2 .close img {height: 35px;}
.moveWraphead { padding: 20px 50px; background:#575C68; color: #fff; font-size: 18px; }
.moveWrapbody { padding: 0 50px 20px; }
.moveWrap2 h4 {font-size: 16px;font-weight: bold;margin: 40px 0 18px;position: relative;color: #575C68;}
.moveWrap2 table {width: 800px;text-align: center;}
.moveWrap2 th {height: 40px;background: #828793; border: solid 1px #DDDEE2;color: #fff;vertical-align: middle;}
.moveWrap2 table tr:nth-child(2) th { background:#EEF0F5; color: #575C68; }
.moveWrap2 .scroll table tr:nth-child(2) th { background:#828793; color: #fff;}
.moveWrap2 td {height: 40px;background: #FBFCFD;vertical-align: middle;border: solid 1px #EEF0F5;white-space: nowrap;color: #575C68;overflow: hidden;text-overflow: ellipsis;position: relative;}
.moveWrap2 input {width: 50px;border: 1px solid #0000F0;background: #fff;border-radius: 3px;color: #575C68;text-align: center;}
.moveWrap2 input.in {width: 90%;height: 28px;}
.moveWrap2 input.in.password {-webkit-text-security: disc;-moz-webkit-text-security: disc;-moz-text-security: disc;}
.moveWrap2 .accountInfo input.in { width: 54%; }
.moveWrap2 .accountInfo input.in.w45w {width: 45%;}
.moveWrap2 select.in {width: 80px;height: 28px;border: 1px solid #60452a;background: #fff;color: #575C68;margin-right: 5px;}
.moveWrap2 p {margin-top: 25px;color: #2A40B9;font-size: 12px;text-align: right;}
.moveWrap2 a.btn {margin-top: 25px;background: #2F4DF2;font-size: 16px;padding: 0 55px;height: 35px;color: #fff;line-height: 35px;text-align: center;border-radius: 5px;display: inline-block;margin-bottom: 18px;float: right;}
.tabmenu_make > ul {display: flex; gap: 10px; }
  .tabmenu_make > ul li {border: 1px solid; border-radius: 10px; padding: 8px 15px; cursor: pointer; }
  .tabmenu_make > ul li.current {background: #fff;color: #575C68;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
<style scoped src="@/styles/striNew.css"></style>
